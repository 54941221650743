<template>
  <b-container>
    <div v-if="tradeStudy !== undefined">
      <b-row>
        <b-col id="ts-info-card" cols="2">
          <info-card :trade-study="tradeStudy">
            <b-button
              v-if="tradeStudy.properties.locked"
              variant="outline-success"
              class="mx-auto"
              @click="unlockTS"
            >
              <feather-icon
                icon="UnlockIcon"
                size="16"
              />
              Unlock
            </b-button>
            <b-button
              v-else
              variant="outline-warning"
              class="mx-auto"
              @click="lockTS"
            >
              <feather-icon
                icon="LockIcon"
                size="16"
              />
              Lock
            </b-button>
          </info-card>
        </b-col>

        <b-col id="ts-process-cards">
          <b-row>
            <options-criteria-select class="w-100" />
          </b-row>
          <b-row>
            <criteria-weighting class="w-100" />
          </b-row>
          <b-row>
            <criteria-scoring class="w-100" />
          </b-row>
          <b-row>
            <final-results class="w-100" />
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <div class="w-100 d-inline-flex justify-content-center mt-4">
        <div class="d-flex flex-column align-items-center">
          <h5 class="text-warning mb-2">
            <atom-spinner class="animate-pulse" />
          </h5>
          <h5>
            Loading Trade Study...
          </h5>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import {
  computed,
  onMounted,
  ref,
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import InfoCard from '@/views/TradeStudy/components/dedicatedPageCards/TradeStudyInfoCard.vue'
import OptionsCriteriaSelect from '@/views/TradeStudy/components/dedicatedPageCards/OptionsCriteriaSelectCard.vue'
import CriteriaWeighting from '@/views/TradeStudy/components/dedicatedPageCards/CriteriaWeightingCard.vue'
import CriteriaScoring from '@/views/TradeStudy/components/dedicatedPageCards/CriteriaScoringCard.vue'
import FinalResults from '@/views/TradeStudy/components/dedicatedPageCards/TradeStudyFinalResultsCard.vue'

export default {
  name: 'TradeStudySideCard',
  components: {
    AtomSpinner,
    InfoCard,
    OptionsCriteriaSelect,
    CriteriaWeighting,
    CriteriaScoring,
    FinalResults,
  },
  setup() {
    onMounted(() => {
      // Get the Trade Study
      fetchTradeStudy()
    })

    const { route } = useRouter()
    const routeParams = computed(() => route.value.params)
    const routerTradeStudyId = computed(() => routeParams.value.tradeId)

    const tradeStudy = ref(undefined)
    const fetchTradeStudy = () => {
      store
        .dispatch('tradestudies/getSelectedTradeStudy', routerTradeStudyId.value)
        .then(data => {
          tradeStudy.value = data
          store.commit('app/SET_DYNAMIC_PAGE_TITLE', tradeStudy.value.properties.name)
        })
    }

    // Lock Management
    const lockTS = () => {
      store
        .dispatch('tradestudies/lockTradeStudy', routerTradeStudyId.value)
        .then(() => { fetchTradeStudy() })
    }
    const unlockTS = () => {
      store
        .dispatch('tradestudies/unlockTradeStudy', routerTradeStudyId.value)
        .then(() => { fetchTradeStudy() })
    }

    return {
      tradeStudy,
      lockTS,
      unlockTS,
    }
  },
}
</script>
