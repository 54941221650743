<template>
  <b-card
    id="ts-final-results"
  >
    <b-card-title>
      Results
    </b-card-title>

    <!--<pre>{{ localResults }}</pre>-->
    <b-row class="d-flex">
      <!-- dropdown and chart -->
      <b-col class="mx-auto">
        <!-- apex chart -->
        <vue-apex-charts
          :key="barKey"
          type="bar"
          height="300"
          :options="chartOptions"
          :series="series"
        />
      </b-col>
    </b-row>

    <b-card-body class="d-flex flex-row justify-content-between">
      <div
        v-for="(opt, index) in localResults"
        :key="index"
        class="d-flex flex-column"
      >
        <span class="font-medium-3 font-weight-bolder mr-1" :class="getColour(opt.highest)">{{ opt.name }}</span>
        <div>
          <span class="font-weight-bolder">Total Score: </span>
          {{ opt.total_score }}
        </div>
        <div
          v-for="(crit, index2) in opt.scores"
          :key="index2"
          class="my-50"
        >
          <div class="mr-1">
            <span class="font-weight-bolder">Criteria: </span>
            {{ crit.criteria_name }}
          </div>
          <div>
            <span class="font-weight-bolder">Score: </span>
            {{ crit.score }}
          </div>
        </div>
        <!--<pre>{{ opt }}</pre>-->
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { kFormatter } from '@core/utils/filter'
import store from '@/store'

export default {
  name: 'FinalResults',
  components: {
    BCard,
    VueApexCharts,
  },
  setup() {
    // console.log({ $themeColors })
    function totalScores(array) {
      let output = 0
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const score in array) {
        // console.log(array[score])
        output += array[score].score
      }
      return Math.floor(output * 100) / 100
    }

    // Initialise variables
    const localResults = ref([])

    // Graph stuff
    const series = ref([])
    const chartOptions = ref({
      chart: {
        type: 'bar',
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      xaxis: {
        type: 'category',
        categories: [],
        labels: {
          style: {
            // colors: $themeColors.dark,
            // cssClass: 'results-chart',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            // colors: 'white',
            // cssClass: 'results-chart',
          },
        },
      },
      legend: {
        labels: {
          // colors: 'white',
        },
      },
      fill: {
        opacity: 1,
      },
      // dataLabels: {
      //   style: {
      //     colors: ['#F44336', '#E91E63', '#9C27B0'],
      //   },
      // },
      colors: [
        $themeColors.primary,
        $themeColors.info,
        $themeColors.success,
        $themeColors.warning,
        $themeColors.danger,
        $themeColors.secondary,
      ],
      // markers: {
      //   colors: ['#F44336', '#E91E63', '#9C27B0'],
      // },
    })
    const barKey = ref(1)

    function setLocalResults() {
      if (store.state.tradestudies.selectedStudy.scores.length > 0) {
        localResults.value = []
        const opts = store.state.tradestudies.selectedStudy.options
        const { criteria } = store.state.tradestudies.selectedStudy
        const { scores } = store.state.tradestudies.selectedStudy

        const highestVal = {
          index: 0,
          val: 0,
        }

        for (let i = 0; i < opts.length; i++) {
          const temp = scores.filter(obj => obj.option_id === opts[i].id)
          localResults.value.push({
            id: opts[i].id,
            name: opts[i].name,
            total_score: 0,
            highest: false,
            scores: temp.map(x => ({
              score: x.score.weighted_score,
              criteria_id: x.criteria_id,
              criteria_name: criteria.find(obj => obj.id === x.criteria_id).name,
            })),
          })

          localResults.value[i].total_score = totalScores(localResults.value[i].scores)

          // Find highest value
          if (i === 0) {
            highestVal.val = localResults.value[i].total_score
          } else if (localResults.value[i].total_score > highestVal.val) {
            highestVal.index = i
            highestVal.val = localResults.value[i].total_score
          }
        }

        localResults.value[highestVal.index].highest = true

        // Prepare chart data array
        series.value = criteria.map(criterion => ({
          name: criterion.name,
          id: criterion.id,
          data: [],
        }))

        chartOptions.value.xaxis.categories = []
        for (let i = 0; i < localResults.value.length; i++) {
          const _result = localResults.value[i]
          // Set category (option) first
          chartOptions.value.xaxis.categories.push(_result.name)
          // For each option, push scores to chart data array
          for (let j = 0; j < _result.scores.length; j++) {
            const _score = _result.scores[j]
            const tempSeriesObj = series.value.find(obj => obj.id === _score.criteria_id)
            tempSeriesObj.data.push(_score.score)
          }
        }

        barKey.value += 1
      }
    }
    watch(
      () => store.state.tradestudies.selectedStudy,
      () => setLocalResults(),
    )
    setLocalResults()

    // Misc
    function getColour(val) {
      return val ? 'text-success' : 'text-secondary'
    }

    return {
      localResults,

      series,
      chartOptions,
      barKey,

      getColour,
    }
  },
  methods: {
    kFormatter,
  },
}
</script>

<style lang="scss">
.dark-layout {
  .apexcharts-xaxis-label, .apexcharts-yaxis-label, .apexcharts-legend-text {
    fill: #d0d2d6 !important;
    color: #d0d2d6 !important;
  }
}
</style>
