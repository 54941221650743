<template>
  <b-modal
    id="delete-selected-component"
    title="Delete component link"
    centered
    size="sm"
    @ok="deleteComponentLink"
  >
    <div v-if="deleteItem.item">
      Are you sure you want to remove link to <span class="text-primary font-weight-bolder">{{ deleteItem.item.name }}</span>?
    </div>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="outline-secondary"
        @click="cancel"
      >
        Cancel
      </b-button>

      <b-button
        variant="danger"
        @click="ok"
      >
        Delete
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'DeleteSelectedComponents',
  props: {
    deleteItem: {
      type: Object,
      required: true,
    },
  },
  methods: {
    deleteComponentLink() {
      if (this.deleteItem.type === 'option') this.$store.dispatch('tradestudies/deleteOptionsLinks', this.deleteItem.item.id)
      if (this.deleteItem.type === 'criteria') this.$store.dispatch('tradestudies/deleteCriteriaLinks', this.deleteItem.item.id)
    },
  },
}
</script>

<style scoped>

</style>
