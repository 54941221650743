<template>
  <b-card
    id="ts-criteria-scoring"
  >
    <b-card-title class="d-flex flex-row justify-content-between">
      Enter Scores
      <b-button-group>
        <b-button variant="outline-success" size="sm" @click="createLocalScores">
          Clear
        </b-button>
        <b-button variant="outline-info" size="sm" @click="loadStoreScores">
          Revert
        </b-button>
      </b-button-group>
    </b-card-title>

    <div
      v-for="(criteria, index) in localScores"
      :key="index"
      class="d-flex flex-column mb-75"
    >
      <span class="font-weight-bolder">{{ criteria.name }}</span>
      <span class="font-small-3">Weight: {{ criteria.weight }}</span>
      <div
        v-for="(option, optIndex) in criteria.optionScores"
        :key="optIndex"
      >
        <hr>
        <b-row>
          <b-col cols="2">
            {{ option.name }}
          </b-col>
          <b-col>
            <b-form-group label="Justification" label-for="opt-justification">
              <b-form-input v-model="option.justification" />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Score" label-for="opt-score">
              <b-form-input v-model="option.raw_score" @input="calcWeight(option, criteria)" />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Weighted score" label-for="opt-weight">
              <b-form-input v-model="option.weighted_score" disabled />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>

    <div id="ts-score-buttons" class="d-flex">
      <b-button
        variant="primary"
        class="mx-auto mt-1"
        @click="submitScores"
      >
        Submit Scores & Calculate Result
      </b-button>
    </div>
    <!--<pre>{{ localScores }}</pre>-->
  </b-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default {
  name: 'CriteriaScoring',
  setup() {
    // Init the data
    const localScores = ref([])

    // Create a new template
    function createLocalScores() {
      const opts = store.state.tradestudies.selectedStudy.options
      const crits = store.state.tradestudies.selectedStudy.criteria
      localScores.value = []

      for (let i = 0; i < crits.length; i++) {
        const optsArray = []
        for (let j = 0; j < opts.length; j++) {
          optsArray.push(({
            id: opts[j].id,
            name: opts[j].name,
            weighted_score: 0,
            raw_score: 0,
            justification: '',
          }))
        }

        localScores.value.push({
          id: crits[i].id,
          name: crits[i].name,
          weight: Math.floor(crits[i].weight * 100) / 100,
          optionScores: optsArray,
        })
      }
    }
    // Create a template from database values
    function loadStoreScores() {
      const opts = store.state.tradestudies.selectedStudy.options
      const crits = store.state.tradestudies.selectedStudy.criteria
      const { scores } = store.state.tradestudies.selectedStudy
      localScores.value = []

      for (let i = 0; i < crits.length; i++) {
        localScores.value.push({
          id: crits[i].id,
          name: crits[i].name,
          weight: Math.floor(crits[i].weight * 100) / 100,
          optionScores: [],
        })

        for (let j = 0; j < opts.length; j++) {
          const temp = scores.find(obj => obj.option_id === opts[j].id && obj.criteria_id === crits[i].id)
          // console.log({ temp })
          localScores.value[i].optionScores.push(({
            id: opts[j].id,
            name: opts[j].name,
            raw_score: temp ? temp.score.raw_score : 0,
            // Weighted score should be reactive in case the user changes the weight slider
            weighted_score: temp ? Math.floor(temp.score.raw_score * localScores.value[i].weight * 100) / 100 : 0,
            justification: temp ? temp.score.justification : '',
          }))
        }
      }
    }

    // Set the appropriate data structure
    function getLocalScores() {
      const selectedTS = store.state.tradestudies.selectedStudy
      if (selectedTS.scores.length > 0) {
        loadStoreScores()
      } else if (selectedTS.criteria.length > 0 && selectedTS.options.length > 0) {
        createLocalScores()
      }
    }
    watch(
      () => store.state.tradestudies.selectedStudy,
      () => getLocalScores(),
    )
    getLocalScores()

    function submitScores() {
      const output = []
      for (let i = 0; i < localScores.value.length; i++) {
        for (let j = 0; j < localScores.value[i].optionScores.length; j++) {
          // console.log(localScores.value[i].name, localScores.value[i].optionScores[j].name)
          output.push({
            option_id: localScores.value[i].optionScores[j].id,
            criteria_id: localScores.value[i].id,
            properties: {
              weighted_score: localScores.value[i].optionScores[j].weighted_score,
              raw_score: localScores.value[i].optionScores[j].raw_score,
              justification: localScores.value[i].optionScores[j].justification,
            },
          })
        }
      }
      const payload = {
        data: output,
      }

      store.dispatch('tradestudies/createScoreLinks', payload)
    }

    // Template stuff
    function calcWeight(opt, crit) {
      opt.weighted_score = Math.floor(opt.raw_score * crit.weight * 100) / 100
    }

    return {
      localScores,
      submitScores,
      calcWeight,
      createLocalScores,
      loadStoreScores,
    }
  },
}
</script>

<style scoped>

</style>
