<template>
  <b-card
    id="ts-criteria-weighting"
  >
    <b-card-title>
      Rank Weights
    </b-card-title>

    <b-form-group
      v-for="(crit, index) in combinationsArray"
      :key="index"
    >
      <b-row class="mb-75">
        <b-col cols="3">
          {{ crit.leftValue.name }}
        </b-col>
        <b-col>
          <vue-slider
            v-model="crit.sliderValue"
            :absorb="true"
            :data="sliderData"
            :data-value="'id'"
            :data-label="'value'"
            tooltip="none"
            :process="false"
          />
        </b-col>
        <!--<pre>slider Value: {{ crit.sliderValue }}</pre>-->
        <b-col cols="3" class="d-flex flex-row-reverse">
          {{ crit.rightValue.name }}
        </b-col>
      </b-row>
    </b-form-group>

    <div id="ts-weight-buttons" class="d-flex">
      <b-button
        variant="primary"
        class="mx-auto mt-1"
        @click="submitWeights"
      >
        Submit Weights
      </b-button>
    </div>
    <!--<pre>{{ combinationsArray }}</pre>-->
  </b-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import VueSlider from 'vue-slider-component'

export default {
  name: 'CriteriaWeighting',
  components: {
    VueSlider,
  },
  setup() {
    // const sliderData = ref([-8, -6, -4, -2, 0, 2, 4, 6, 8])
    const sliderData = ref([
      { id: 0, value: '<' },
      { id: 1, value: '' },
      { id: 2, value: '-' },
      { id: 3, value: '' },
      { id: 4, value: '-' },
      { id: 5, value: '' },
      { id: 6, value: '-' },
      { id: 7, value: '' },
      { id: 8, value: '|' },
      { id: 9, value: '' },
      { id: 10, value: '-' },
      { id: 11, value: '' },
      { id: 12, value: '-' },
      { id: 13, value: '' },
      { id: 14, value: '-' },
      { id: 15, value: '' },
      { id: 16, value: '>' },
    ])

    const combinationsArray = ref([])
    function findCombos() {
      if (store.state.tradestudies.selectedStudy.criteria.length > 0) {
        combinationsArray.value = []
        const crits = store.state.tradestudies.selectedStudy.criteria
        const comboLength = crits.length
        for (let i = 0; i < comboLength; i++) {
          // noinspection UnnecessaryLocalVariableJS
          for (let j = i + 1; j < comboLength; j++) {
            combinationsArray.value.push({
              leftValue: {
                name: crits[i].name,
                id: crits[i].id,
              },
              rightValue: {
                name: crits[j].name,
                id: crits[j].id,
              },
              sliderValue: 8,
            })
            // If stored slider data exists
            if (store.state.tradestudies.selectedStudy.sliders && store.state.tradestudies.selectedStudy.sliders.length > 0) {
              // Variables for shorthand
              const slider = store.state.tradestudies.selectedStudy.sliders
              const currCombination = combinationsArray.value[combinationsArray.value.length - 1] // Current combination will always be last in the array after an `array.push()`
              const currLeftId = currCombination.leftValue.id
              const currRightId = currCombination.rightValue.id
              // Find the stored slider value
              // x.components[0].id = Stored Left Value
              // x.components[1].id = Stored Right Value
              // OR Comparison needed as Left/Right order might not be the same
              const storedSliderValue = slider
                .find(x => (x.components[0].id === currLeftId && x.components[1].id === currRightId) || (x.components[1].id === currLeftId && x.components[0].id === currRightId))?.slider?.slider_value
              // Set the *current* slider value to *stored* slider value
              currCombination.sliderValue = storedSliderValue
            }
          }
        }
      }
    }
    watch(
      () => store.state.tradestudies.selectedStudy.criteria,
      () => findCombos(),
    )
    findCombos()

    function submitWeights() {
      const payload = {
        criteria: store.state.tradestudies.selectedStudy.criteria,
        criteria_comparisons: combinationsArray.value,
      }

      store.dispatch('tradestudies/getWeights', payload)
    }

    return {
      // Slider stuff
      sliderData,

      combinationsArray,
      submitWeights,
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
