<template>
  <b-card
    id="ts-options-criteria-select"
  >
    <b-card-title>
      Select Options and Criteria
    </b-card-title>
    <div
      v-if="isFetchDataLoading"
      id="ts-fetch-spinner"
      class="d-flex"
    >
      <span class="mx-auto font-large-1">
        <b-spinner style="width: 5rem; height: 5rem;" />
      </span>
    </div>
    <div v-else id="ts-select-body">
      <div id="ts-options-select" class="d-flex flex-row">
        <b-form-group label="Select options:" label-for="options-select" class="flex-grow-1">
          <v-select
            id="options-select"
            :value="options"
            class="select-class"
            multiple
            label="name"
            :options="localOptions"
            @option:selecting="addOptions"
            @option:deselecting="deleteOptions"
          />
        </b-form-group>
      </div>

      <div id="ts-criteria-select" class="d-flex flex-row">
        <b-form-group label="Select criteria:" label-for="criteria-select" class="flex-grow-1">
          <v-select
            id="criteria-select"
            :value="criteria"
            class="select-class"
            multiple
            label="name"
            :options="localCriteria"
            @option:selecting="addCriteria"
            @option:deselecting="deleteCriteria"
          />
        </b-form-group>
      </div>
    </div>

    <delete-selected-components
      :delete-item="deleteItem"
    />
  </b-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import DeleteSelectedComponents from '@/views/TradeStudy/components/modals/DeleteSelectedComponents.vue'

export default {
  name: 'OptionsSelect',
  components: {
    vSelect,
    DeleteSelectedComponents,
  },
  setup(props, context) {
    // Get the options
    const localOptions = ref([])
    function getOptions() {
      // console.log(store.state.domainModel.performers)
      if (store.state.tradestudies.allOptions) {
        localOptions.value = JSON.parse(JSON.stringify(store.state.tradestudies.allOptions))
      }
    }
    watch(
      () => store.state.tradestudies.allOptions,
      () => {
        getOptions()
      },
    )

    // Get the criteria
    const localCriteria = ref([])
    function getCriteria() {
      if (store.state.tradestudies.allCriteria) {
        localCriteria.value = store.state.tradestudies.allCriteria
      }
    }
    watch(
      () => store.state.tradestudies.allCriteria,
      () => {
        getCriteria()
      },
    )

    const isFetchDataLoading = ref(false)
    function fetchCritsOpts() {
      isFetchDataLoading.value = true
      store.dispatch('tradestudies/getBothCritsOpts')
        .then(() => { isFetchDataLoading.value = false })
    }
    // Call at least once
    fetchCritsOpts()

    // Selected arrays to submit
    const options = ref([])
    const criteria = ref([])

    // Update selected arrays if data is avaliable
    function setLocalArrays() {
      options.value = store.state.tradestudies.selectedStudy.options
      criteria.value = store.state.tradestudies.selectedStudy.criteria
    }
    watch(
      () => store.state.tradestudies.selectedStudy.options,
      () => setLocalArrays(),
    )
    setLocalArrays()

    const deleteItem = ref({})

    function addOptions(item) {
      // console.log('Update')
      const payload = {
        id: store.state.tradestudies.selectedStudy.properties.id,
        link: {
          data: [
            {
              id: item.id,
              properties: {},
            },
          ],
        },
      }
      store.dispatch('tradestudies/createOptionsLinks', payload)
    }

    function deleteOptions(item) {
      // console.log('Delete', item.id)
      deleteItem.value = {
        item,
        type: 'option',
      }
      context.root.$bvModal.show('delete-selected-component')
    }

    function addCriteria(item) {
      // console.log('Update')
      const payload = {
        id: store.state.tradestudies.selectedStudy.properties.id,
        link: {
          data: [
            {
              id: item.id,
              properties: {},
            },
          ],
        },
      }
      store.dispatch('tradestudies/createCriteriaLinks', payload)
    }

    function deleteCriteria(item) {
      deleteItem.value = {
        item,
        type: 'criteria',
      }
      context.root.$bvModal.show('delete-selected-component')
    }

    return {
      // Input
      localOptions,
      localCriteria,

      // Misc
      isFetchDataLoading,

      // Output
      options,
      criteria,
      deleteItem,

      // - Options
      addOptions,
      deleteOptions,
      // - Criteria
      addCriteria,
      deleteCriteria,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.select-class .vs__dropdown-option--selected {
  background: rgba(247, 153, 28, 0.82);
  color: #ffffff !important;
  /*font-weight: bolder;*/
}
</style>
