<template>
  <b-card class="card-app-design">
    <div class="design-group mb-0">
      <div>
        <h6 class="section-label">
          Name:
        </h6>
        <b-card-text class="font-small-4 mt-50 mb-1">
          {{ localTradeStudy.name }}
        </b-card-text>
      </div>

      <div>
        <h6 class="section-label">
          Description:
        </h6>
        <b-card-text class="font-small-2 mt-50 mb-1">
          {{ localTradeStudy.description }}
        </b-card-text>
      </div>

      <div>
        <h6 class="section-label">
          Scope:
        </h6>
        <b-card-text class="font-small-2 mt-50 mb-1">
          {{ localTradeStudy.scope }}
        </b-card-text>
      </div>

      <div>
        <h6 class="section-label">
          Created By:
        </h6>
        <b-card-text class="font-small-3 mt-50 mb-1">
          {{ getUserUsername(localTradeStudy.created_by) }}
        </b-card-text>
      </div>

      <div>
        <h6 class="section-label">
          Created On:
        </h6>
        <b-card-text class="font-small-3 mt-50 mb-1">
          {{ createdDate(localTradeStudy.created) }}
        </b-card-text>
      </div>

      <div>
        <h6 class="section-label">
          Details:
        </h6>

        <p class="font-small-3 m-0">
          <span class="font-weight-bolder text-primary">{{ localOptions.length }}</span> Options
        </p>
        <p class="font-small-3 m-0">
          <span class="font-weight-bolder text-primary">{{ localCriteria.length }}</span> Criteria
        </p>
      </div>

      <div id="ts-info-body-slot" class="d-flex flex-row align-content-center mt-1">
        <slot />
      </div>
    </div>
    <!--<pre>{{ tradeStudy }}</pre>-->
  </b-card>
</template>

<script>
import moment from 'moment'
import { ref, watch } from '@vue/composition-api'

export default {
  name: 'TSInfoCard',
  props: {
    tradeStudy: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const localTradeStudy = ref({
      created: '',
      created_by: '',
      criteria: [],
      id: '',
      name: 'Empty',
      options: [],
    })
    const localOptions = ref([])
    const localCriteria = ref([])

    function setTradeStudy() {
      if (props.tradeStudy) {
        localTradeStudy.value = props.tradeStudy.properties
        localOptions.value = props.tradeStudy.options
        localCriteria.value = props.tradeStudy.criteria
      }
    }
    watch(
      () => props.tradeStudy,
      () => setTradeStudy(),
    )
    setTradeStudy()

    return {
      localTradeStudy,
      localOptions,
      localCriteria,
    }
  },
  methods: {
    formatToLocalTime(date) {
      const testDateUtc = moment.utc(date)
      return moment(testDateUtc).local()
    },
    createdDate(date) {
      const localDate = this.formatToLocalTime(date)
      return localDate.format('LLLL')
    },
  },
}
</script>

<style scoped>

</style>
